<template>
  <div class="zmail-detail-view">
    <page-block :title="$t('title.plan')" class="zmail-detail-view__block">
      <main-card>
        <div class="standart-title">
          {{ `${tariff.pricelist} (${$tc('period.month_c', tariff.period)})` }}
        </div>
        <div class="zmail-detail-view__promise">
          <div class="note-text">
            {{ $t('activeUnder', { date: $d(tariff.expiredate, 'short') }) }}
          </div>
          <plain-button
            v-if="tariff.promise !== 'null'"
            v-tooltip="{
              content: promiseOn,
              autoHide: false,
              placement: 'top-start',
              container: false,
            }"
            icon="promise-icon"
            class="allowed-user-rights__promise"
          />
        </div>
        <template v-if="hasProlongTool || hasChangepricelistTool" #bodyEnd>
          <div class="tariff-plan__btns">
            <base-button
              v-if="hasProlongTool"
              class="tariff-plan__btn"
              @click="
                tariff.period_cost === '0.00' ? singleProlongFree(tariff) : singleProlong(tariff)
              "
            >
              {{ $t('actions.prolong') }}
            </base-button>
            <base-button
              v-if="hasChangepricelistTool"
              theme="outlined"
              class="tariff-plan__btn"
              @click="changepricelist"
            >
              {{ $t('actions.changePlan') }}
            </base-button>
          </div>
        </template>
      </main-card>
    </page-block>
    <page-block
      v-if="hasAutoprolong && autoprolongSettings"
      :title="$t('title.autoprolong')"
      class="zmail-detail-view__block"
    >
      <main-card>
        <div class="standart-text">
          {{
            $t(`autoprolong.state.${autoprolongSettings.state}`, {
              period: autoprolongSettings.period,
            })
          }}
        </div>
        <template #bodyEnd>
          <div class="tariff-autoprolong__btns">
            <base-button
              class="tariff-autoprolong__btn"
              @click="autoprolong(tariff, autoprolongSettings.state === 'on' ? 'update' : 'on')"
            >
              {{
                autoprolongSettings.state === 'on'
                  ? $t('autoprolong.actions.update')
                  : $t('autoprolong.actions.on')
              }}
            </base-button>
            <base-button
              v-if="autoprolongSettings.state === 'on'"
              theme="outlined"
              class="tariff-autoprolong__btn"
              @click="autoprolong(tariff, 'off')"
            >
              {{ $t('autoprolong.actions.off') }}
            </base-button>
          </div>
        </template>
      </main-card>
    </page-block>
    <transition name="bubble">
      <page-block v-if="addons.length" :title="$t('title.addons')" class="zmail-detail-view__block">
        <main-card>
          <div class="addons">
            <div v-for="(item, i) in addons" :key="i" class="addons__item">
              <div class="addons__label standart-title">
                {{ item.label }}
              </div>
              <div
                v-if="!item.value.includes('http') && !item.label.includes('Перейти')"
                class="addons__value standart-text"
              >
                {{ item.value }}
              </div>
              <plain-button
                v-else-if="item.label.includes('Перейти')"
                target="_blank"
                color="primary"
                class="addons__link"
                @click="goToWeb(item)"
              >
                {{ item.value }}
              </plain-button>
              <plain-button v-else :href="item.value" target="_blank" color="primary">
                {{ item.value }}
              </plain-button>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
    <transition name="bubble">
      <page-block v-if="config.length" :title="$t('title.config')" class="zmail-detail-view__block">
        <main-card>
          <div class="addons">
            <div v-for="(item, i) in config" :key="i" class="addons__item">
              <div class="addons__label standart-title">
                {{ item.label }}
              </div>
              <div v-if="!item.label.includes('Порты')" class="addons__value standart-text">
                {{ item.value }}
              </div>
              <div v-else-if="item.label.includes('обычного')" class="addons__block">
                <div v-for="(items, n) in config[2].value" :key="n" class="addons__block-item">
                  <div class="note-text">
                    {{ items.protocol }}
                  </div>
                  <div>
                    {{ items.port }}
                  </div>
                </div>
              </div>
              <div v-else-if="item.label.includes('безопасного ')" class="addons__block">
                <div v-for="(items, n) in config[3].value" :key="n" class="addons__block-item">
                  <div class="note-text">
                    {{ items.protocol }}
                  </div>
                  <div>
                    {{ items.port }}
                  </div>
                </div>
              </div>
              <plain-button v-else :href="item.value" target="_blank" color="primary">
                {{ item.value }}
              </plain-button>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import PageBlock from '../components/PageBlock';
import MainCard from '@/components/MainCard/MainCard';
import ZmailProlongSingle from '../../../components/ZmailProlongSingle.vue';
import ZmailProlongSinglePayments from '../../../components/ZmailProlongSinglePayments';
import { ZmailTariff } from '@/models/BillMgr/ZmailTariff';
import changePricelist from '@/mixins/billmgr/changePricelist';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import prolongAuto from '@/mixins/billmgr/prolongAuto';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import prolongText from '@/mixins/prolongText';
import { BillMgrTool } from '@/models/BillMgr/Tools';
import serialize from '@/utils/serialize';
export default {
  name: 'ZmailView',
  components: {
    PageBlock,
    MainCard,
  },
  mixins: [changePricelist, prolongSingle, prolongSingleFree, prolongAuto, prolongText],
  props: {
    tariff: {
      type: ZmailTariff,
      required: true,
      validator: obj => obj instanceof ZmailTariff,
    },
  },
  data() {
    return {
      // web: {
      //   label: 'Почтовый web-интерфейс',
      //   value: 'http://zmail.ru',
      // },
      // panel: {
      //   label: 'Перейти в веб. интерфейс',
      //   value: 'http://zmail.ru',
      // },
      //costAutoProlong: 0,
      moduleMain: 'moduleZmail',
      moduleOrder: 'moduleZmailOrder',
      itemtype: 'zmail',
      singleProlongComponentFirst: ZmailProlongSingle,
      singleProlongComponent: ZmailProlongSinglePayments,
    };
  },
  computed: {
    config() {
      if (!this.tariff.addons || !this.tariff.addons.mailname.value) return [];
      return [
        {
          label: 'Имя пользователя',
          value: this.tariff.addons.mailname.value,
        },
        {
          label: 'Сервер для отправки и получения',
          value: 'mail' + '.' + this.tariff.addons.mailname.value.split('@')[1],
        },
        {
          label: 'Порты для обычного соединения',
          value: [
            {
              protocol: 'smtp: ',
              port: ' 25',
            },
            {
              protocol: 'pop3: ',
              port: ' 110',
            },
            {
              protocol: 'imap: ',
              port: ' 143',
            },
          ],
        },
        {
          label: 'Порты  для безопасного соединения (SSL)',
          value: [
            {
              protocol: 'smtp:',
              port: '465',
            },
            {
              protocol: 'pop3:',
              port: '995',
            },
            {
              protocol: 'imap:',
              port: '993',
            },
          ],
        },
      ];

      // return Object.values(this.tariff.addons).map(addon => {
      //   let item = {
      //     label: addon.desc,
      //     value: addon.value,
      //   };
      //   if (addon.measure) item.value += ` ${addon.measure}`;
      //   return item;
      // });
    },
    addons() {
      if (!this.tariff.addons) return [];
      const list = Object.values(this.tariff.addons)
        .filter(x => x.name === 'mailname')
        .map(addon => {
          let item = {
            label: addon.label,
            value: addon.value,
          };
          if (addon.measure) item.value += ` ${addon.measure}`;
          return item;
        });
      // list.push(this.web);
      // list.push(this.panel);
      list.push(this.web[0]);
      return list;
    },
    web() {
      const { gotoserver } = this.tools;
      return [
        {
          label: 'Перейти в веб. интерфейс',
          value: 'Перейти',
          tool: gotoserver,
        },
      ];
    },
    tools() {
      return this.$store.state.moduleZmail.tools;
    },
    cost() {
      return this.tariff.cost;
    },
    hasChangepricelistTool() {
      return (
        !!this.tools &&
        !!this.tools.changepricelist &&
        this.tools.changepricelist.isEnable(this.tariff)
      );
    },
    hasProlongTool() {
      return (
        this.tariff.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.tariff)
      );
    },
    hasAutoprolong() {
      return this.tariff.autoprolongAvailable;
    },
    autoprolongSettings() {
      return this.tariff.autoprolongSettings;
    },
  },
  methods: {
    goToWeb(item) {
      if (item.tool && item.tool instanceof BillMgrTool) {
        try {
          this.handleBillMgrTool(item);
        } catch (e) {
          console.error(e);
        }
      } else if (!item.attrs) alert(`id: ${this.tariff.id}, \r\naction: ${item.title}`);
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    goToWindow(item) {
      this.$store
        .dispatch('moduleZmail/fetchBillMgrToolAction', {
          func: item.tool.func,
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            window.open(data.ok.v + payload);
          }
        })
        .catch(e => {
          this.showError(e);
        });
    },
  },
  //mounted() {
  // if (this.hasProlongTool) this.getCost();
  //},
};
</script>

<i18n>
{
  "ru": {
    "promise" : "Для услуги активирован обещанный платеж до {promise}",
    "title": {
      "plan": "Тарифный план",
      "addons": "Дополнительная информация",
      "config": "Данные для подключения почтовых клиентов",
      "stats": "Статистика",
      "autoprolong": "Автопродление"
    },
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    },
    "changepricelist": {
      "title": "Смена тарифа",
      "success": "Смена тарифа прошла успешно. Данные о тарифе скоро обновятся."
    },
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "free": "Активировать",
      "cancel": "Отмена",
      "confirm": "Оплатить",
      "order": "В корзину"
    },
    "autoprolong": {
      "title": "Автопродление",
      "state": {
        "on": "Включено автоматическое продление на {period}",
        "off": "Автоматическое продление выключено"
      },
      "actions": {
        "on": "Включить",
        "off": "Выключить",
        "update": "Изменить"
      },
      "modals": {
        "off": {
          "title": "Отключить автопроделние",
          "desc": "Автопродление для услуги #{id} будет отключено",
          "confirm": "Да, отключить"
        },
        "update": {
          "title": "Изменить настройки автопродления",
          "confirm": "Сохранить"
        },
        "on": {
          "title": "Включить автопродление",
          "confirm": "Включить"
        }
      },
      "result": {
        "success": {
          "title": "Отправлено успешно",
          "desc": "Настройки автопродления для услуги #{id} были успешно изменены",
          "confirm": "Хорошо"
        },
        "error": {
          "title": "Произошла ошибка",
          "desc": "Настройки автопродления для услуги #{id} изменить не удалось. Попробуйте позднее или обратитесь за помощью в поддержку",
          "confirm": "Понятно"
        }
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.zmail-detail-view{
  &__promise{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}
.allowed-user-rights{
  &__promise{
    margin-left: 1px;
  }
}

.tariff {
  &-plan,
  &-autoprolong {

    &__btn {
      min-width: 160px;
      & + & {
        margin-top: 14px;

        +breakpoint(sm-and-up) {
          margin-top: 0;
          margin-left: 1.5rem;
        }
      }
    }
  }
}

.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
  &__link{
    padding-left: 0;
    padding-right: 0;
  }
  &__block{
    display: flex;
    gap: 15px;

    &-item{
      display: flex;
      gap: 3px;
    }
  }
}
</style>

import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import getLocalizedPeriod from '@/mixins/getLocalizedPeriod';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
import breakpoints from '@/utils/breakpoints';
import wizardPay from './wizardPay';
import handleRedirect from '../billing/handleRedirect';
import qs from 'qs';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
export default {
  mixins: [billMgrToolActions, getLocalizedPeriod, wizardPay, handleRedirect],
  data() {
    return {
      sp_model: null,
      sp_modal: null,
      sp_list: [],
      sp_tariff: null,
      sp_currentPeriod: '',
      sp_total: 0,
      startFunc: 'payment.add',
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    sp_currentValue() {
      return this.sp_list.find(i => i.k === this.sp_currentPeriod) || null;
    },
    balance() {
      return this.$store.state.moduleProfile.profile.balance;
    },
    sp_isBalanceEnough() {
      return this.sp_total <= this.balance;
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    isMobile() {
      return this.screenWidth < breakpoints.sm;
    },
    tools() {
      const splitted = this.moduleMain.split('.');
      console.log(this.moduleMain, splitted);
      const path = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return path.tools;
    },
    // moduleMainPath() {
    //   return this.moduleMain.replaceAll('.', '/');
    // },
  },
  // watch: {
  // sp_isBalanceEnough: function (value) {
  //   if (this.sp_modal) this.sp_toggleConfirmButtonState(value);
  // },
  // },
  methods: {
    singleProlong(tariff) {
      this.sp_tariff = tariff;
      const params = {
        id: tariff.id,
        func: this.tools.prolong.func,
      };
      this.$modals.open({
        name: 'SingleProlong',
        title: this.$t('prolong.title'),
        component: BaseLoader,
        onOpen: inst => (this.sp_modal = inst),
        onClose: () => {
          this.sp_modal = null;
          this.sp_reset();
        },
        footer: {
          confirm: {
            props: { title: this.$t('prolong.confirm'), disabled: false },
            // props: { title: this.$t('prolong.confirm'), disabled: true },
            on: {
              click: () => {
                this.sp_order(true);
              },
            },
          },
          cancel: {
            // props: { title: this.$t('prolong.order'), disabled: true },
            props: { title: this.$t('prolong.order'), disabled: false },
            on: {
              click: () => this.sp_order(),
            },
          },
        },
      });
      this.fetchBillMgrAction(params)
        .then(data => {
          const { model, slist, fields } = data;
          // console.log(fields.prolong_warning.label);
          if (model && slist && model.period) {
            this.sp_model = model;
            this.sp_list = slist.period.map(i => {
              return {
                ...i,
                // cost: parseInt(i.cost),
                cost: parseFloat(i.cost),
                label: this.getLocalizedPeriod(i.k),
              };
            });
            this.sp_currentPeriod = this.sp_model.period;
            Vue.set(this.sp_modal, 'size', 'big');
            Vue.set(this.sp_modal, 'props', {
              tariff: this.sp_tariff,
              list: this.sp_list,
              value: this.sp_currentValue,
              text: fields.prolong_warning.label,
            });
            Vue.set(this.sp_modal, 'on', {
              ready: () => {
                Vue.set(this.sp_modal.footer.cancel.props, 'disabled', false);
                Vue.set(this.sp_modal.footer.confirm.props, 'disabled', false);
                //this.sp_toggleConfirmButtonState(this.sp_isBalanceEnough);
              },
              change: value => {
                this.sp_currentPeriod = value.k;
              },
              ['change-total']: value => {
                this.sp_total = value;
              },
            });
            Vue.set(this.sp_modal, 'component', this.singleProlongComponent);
          } else {
            throw new Error(this.$t('prolong.error'));
          }
        })
        .catch(() => {
          this.$modals.close({ name: this.sp_modal.name });
        });
    },
    sp_reset() {
      this.sp_model = null;
      this.sp_list = [];
      this.sp_tariff = null;
    },
    sp_order(skip = false) {
      const params = {
        elid: this.sp_tariff.id,
        func: this.tools.prolong.func,
        period: this.sp_currentPeriod,
        sok: 'ok',
        clicked_button: 'basket',
        snext: 'ok',
      };
      if (skip) {
        params.newbasket = 'on';
      } else {
        params.newface = '';
      }
      let modal = null;
      this.$modals.open({
        name: 'SingleProlongResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.sendBillMgrAction(params).then(data => {
        if (data && data.ok) {
          if (skip) {
            if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
              this.$store.dispatch('moduleBasket/fetchBasket');
              const { billorder } = qs.parse(data.ok.v);
              this.startParams = { billorder };
              this.runWizardPay()
                .then(async data => {
                  // console.log(data);
                  this.handleRedirect(data.ok);
                  this.$modals.close({ name: 'SingleProlong' });
                  this.showSuccessModal('Счёт успешно сформирован');
                  this.sp_reset();
                  this.sp_updatePageData();
                })
                .catch(() => {
                  const basketItem = this.$store.state.moduleBasket.shadow[billorder][0];
                  if (basketItem) {
                    this.$store
                      .dispatch('moduleBasket/removeFromBasket', basketItem)
                      .finally(() => {
                        this.$store.dispatch('moduleBasket/fetchBasket');
                      });
                  }
                })
                .finally(() => {
                  this.$modals.close({ name: 'SingleProlongResult' });
                });
            }
          } else {
            this.sp_reset();
            this.sp_updatePageData();
            this.$modals.close({ name: 'SingleProlong' });
            Vue.set(modal, 'text', this.$t('prolong.success_basket'));
            Vue.set(modal, 'component', null);
            Vue.set(modal, 'closable', true);
            Vue.set(modal, 'footer', {
              confirm: {
                props: { title: this.$t('good') },
                on: {
                  click: () => {
                    this.$modals.close();
                  },
                },
              },
            });
          }
        } else {
          this.$modals.close({ name: 'SingleProlongResult' });
          this.$modals.close({ name: 'SingleProlong' });
        }
      });
    },
    sp_toggleConfirmButtonState(value) {
      Vue.set(this.sp_modal.footer.confirm.props, 'disabled', !value);
      const note = {
        text: this.$t('balance.notenough'),
        color: 'error',
      };
      Vue.set(this.sp_modal.footer, 'note', value ? null : note);
    },
    sp_updatePageData() {
      // this.$store.dispatch(`${this.moduleMainPath}/updateList`);
      this.$store.dispatch('moduleBasket/fetchBasket');
      this.$store.dispatch('moduleProfile/setProfileInfo');
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
  },
};
